@use "../variables" as *;

// Base button style
@mixin button-base {
  cursor: pointer;
  user-select: none;
  outline: none;

  display: inline-flex;
  height: 40px;
  padding: 0 26px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: transparent;

  text-align: center;
  font-family: $font;
  font-size: 16px;
  font-style: normal;

  transition: background-color $hover-time linear;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:active {
    transform: translateY(1px);
  }

  &.toggled {
    border: 2px solid var(--Border-Gray);
  }
}

@mixin ui-button {
  // Outline button
  .btn-outline {
    @include button-base;

    border: 1.2px solid var(--Orange, #C75000);
    color: var(--Orange, #C75000);
    font-weight: 700;
    line-height: 22px;
    /* 137.5% */
    text-decoration: none;

    &:hover {
      background: rgba(199, 80, 0, 0.08);
    }
  }

  // Transparent button
  .btn-transparent {
    @include button-base;
    border-radius: 0;
    border: none;
    color: var(--Orange, #C75000);
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
  }

  .ui-btn-action {
    @include button-base;
    border-radius: 0;
    border: none;
    color: var(--Orange, #C75000);
    font-weight: 400;
    line-height: 22px;
    height: auto;
    width: auto;
    position: relative;
    padding: 0;

    &:after {
      display: block;
      content: " ";
      position: absolute;
      width: 30px;
      height: 30px;
      left: 0;
      top: 0;
      transform: translate(-25%, -25%);
    }
  }

  // Primary button
  .btn-main,
  .btn-primary {
    @include button-base;
    border: none;
    background: var(--Orange, #C75000);
    color: var(--White, #FFF);
    font-weight: 700;
    line-height: 22px;

    // With right arrow
    &.arrow-right:after {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      background-image: url("/assets/img/arrow-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 10px;

      transition: transform $hover-time linear;
    }

    &:hover {
      background: var(--Orange-hover, #AE4600);

      &.arrow-right:after {
        transform: translateX(5px);
      }
    }
  }

  .btn-gray {
    @include button-base;
    padding: 0 10px;
    border: none;
    color: var(--Blue-Dark, #39444E);
    background: var(--Off-White, #F8F9F9);

    &.transparent {
      background: transparent;
    }

    &:hover {
      color: var(--Black, #151414);
      background: var(--Porcelain, #ECF0F1);
    }

    &>.icon+span:not(:empty) {
      margin-left: 10px;
    }
  }
}