@use "@toast-ui/editor/dist/toastui-editor.css";
@use "./../oex-ui-kit/styles/main";
@use "utils" as *;
@use "txt" as *;
@use "fonts" as *;
@use "variables" as *;

@include main.ui-main;

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
/*
* {
  box-sizing: border-box;
}*/

iframe:not([src]):empty {
  display: none;
}

h1 {
  font-family: $font;
  font-size: 60px;
  margin: 0;
  padding: 0;
  color: var(--Black, #151414);
}

h2 {
  color: var(--Black, #151414);
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
  margin: 0;
  padding: 0;
}

h3 {
  color: var(--Black, #151414);
  font-family: $font;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  padding: 0;

  @media (min-width: $scr-tablet) {
    font-size: 18px;
    line-height: 24px;
  }
}

/*h5 {
  color: var(--Black, #151414);
  font-family: $font;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  padding: 0;
}*/
/*
.status-item {
  @include txt-12;
  line-height: 12px;
  display: flex;
  padding: 0px;
  white-space: nowrap;
  border-radius: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  padding-left: 10px;
  padding-right: 10px;

  & > .ng-value-icon {
    display: none;
  }
}

.ng-value > .status-item {
  white-space: normal;
  padding-right: 2px;
  !*justify-content: flex-start;
  text-align: left;*!
}
// Selected status option
.ng-option-selected > .status-item {
  font-weight: 700;
  border: 2px solid var(--Black);
}*/

st-status {
  //opacity: 0.5;
}
// Status inside dropdown as option
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value > st-status {
  white-space: normal;
  padding-right: 2px;
  //opacity: 1;
}

.ng-value > st-status {
  white-space: normal;
}

  // Selected status inside edit field
.ng-option-selected > st-status {
  font-weight: 700;
  outline: 2px solid var(--Black);
  outline-offset: -2px;
  //opacity: 1;
}

.badge-gp {
  @include txt-12;
  display: inline-block;
  color: var(--Green, #5AD1C6);
  height: 24px;
  padding: 4px 16px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid var(--Green, #5AD1C6);
}

.no-padding {
  padding: 0 !important;
}
